<template>
  <!-- 报名结果 -->
  <div class="signUpSuccessfully">
    <van-nav-bar title="报名结果"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight" />
    <div class="overflow_div">
      <div class="top_tt">
        <img src="@/assets/img/signUpSuccessfully.png"
             alt="" />
        <div class="oval_div"></div>
        <div>
          <div>恭喜您</div>
          <div>本次课程报名成功</div>
        </div>
      </div>
      <div class="footer">
        <div class="get_center">
          <van-button type="info"
                      @click="checkNotes">查看记录</van-button>
        </div>
        <div class="getBack">
          <span @click="backHome">返回主页</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {
    onClickLeft () {
      this.$router.push('/signUpDetails')
    },
    onClickRight () { },
    // 查看记录
    checkNotes () {
      this.$router.push({ path: '/trainingRecord', query: { fromType: '报名成功' } })
    },
    // 返回主页
    backHome () {
      this.$router.push('/')
    }

  }

}
</script>

<style lang="less" scoped>
.signUpSuccessfully {
  height: 100vh;
  overflow: hidden;
  background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    overflow: auto;
    height: calc(100% - 100px);
    background: #fff;
    .top_tt {
      text-align: center;
      padding-top: 180px;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 48px;
      color: #999999;
      img {
        width: 326px;
        height: 286px;
      }
      .oval_div {
        margin: 30px calc(50% - 70px);
        width: 140px;
        height: 18px;
        background: #cccccc;
        border-radius: 50%;
      }
    }
    .footer {
      width: 100%;
      position: absolute;
      bottom: 240px;
      .get_center {
        text-align: center;
        .van-button {
          width: 314px;
          height: 82px;
          background: #2b8df0;
          opacity: 1;
          border-radius: 12px;
          .van-button__text {
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            line-height: 62px;
            color: #ffffff;
          }
        }
      }
      .getBack {
        padding: 20px 0;
        text-align: center;
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        line-height: 62px;
        color: #999999;
      }
    }
  }
}
</style>